body {
  height: 100%;
  padding-left: 300px;
  font-family: Raleway, sans-serif;
  color: #333;
  font-size: 14px;
  line-height: 20px;
}

h1 {
  margin-bottom: 10px;
  font-size: 38px;
  line-height: 44px;
  font-weight: 600;
  text-transform: uppercase;
}

h2 {
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 28px;
  line-height: 36px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
}

h3 {
  margin-bottom: 10px;
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
}

h4 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
}

h5 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  text-transform: uppercase;
}

h6 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
}

p {
  margin-bottom: 5px;
  color: #777;
  line-height: 26px;
  text-transform: none;
}

a {
  -webkit-transition: background-color 500ms ease, color 500ms ease;
  transition: background-color 500ms ease, color 500ms ease;
  color: #00a3d0;
  text-decoration: none;
}

a:hover {
  color: #333;
}

ul {
  margin-top: 0px;
  margin-bottom: 10px;
  padding-left: 20px;
  color: #555;
}

li {
  margin-bottom: 5px;
}

.button {
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 15px 25px;
  border-radius: 20px;
  background-color: #00a3d0;
  -webkit-transition: background-color 500ms ease-in-out;
  transition: background-color 500ms ease-in-out;
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
}

.button:hover {
  background-color: #333;
  color: #00a3d0;
}

.button.slider {
  margin-right: 10px;
  margin-left: 10px;
}

.button.button-white {
  background-color: #fff;
  color: #e3543b;
}

.button.button-white:hover {
  background-color: #e3543b;
  color: #fff;
}

.button.inline {
  position: absolute;
  top: 0px;
  right: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.navigation {
  position: fixed;
  left: 0px;
  top: 0px;
  bottom: 30px;
  overflow: auto;
  width: 300px;
  height: 100%;
  float: left;
  background-color: #000;
  box-shadow: 0 0 100px 1px rgba(0, 0, 0, .18);
}

.navigation-link {
  width: 100%;
  padding-top: 3%;
  padding-bottom: 3%;
  padding-left: 55px;
  -webkit-transition: color 300ms ease;
  transition: color 300ms ease;
  color: hsla(0, 0%, 100%, .6);
  font-weight: 400;
}

.navigation-link:hover {
  color: #00a3d0;
}

.navigation-link.w--current {
  color: #00a3d0;
}

.navigation-menu {
  left: 0px;
  top: 0px;
  right: 0px;
  margin-bottom: 20px;
  float: none;
}

.brand {
  display: block;
  margin-top: 14%;
  margin-bottom: 30px;
  padding-right: 50px;
  padding-left: 50px;
  float: none;
  text-align: center;
}

.banner2 {
  position: relative;
  height: 100%;
  background-color: #424242;
}

.social-wrapper {
  position: relative;
  text-align: center;
}

.social-wrapper._2 {
  position: relative;
  bottom: 0vh;
  text-align: left;
}

.social-icon {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-image: url('../images/facebook_ffffff_100-1.png');
  background-position: 50% 50%;
  background-size: 18px;
  background-repeat: no-repeat;
  opacity: 0.6;
  -webkit-transition: background-color 300ms ease;
  transition: background-color 300ms ease;
}

.social-icon:hover {
  background-color: #00a3d0;
  background-image: url('../images/facebook_ffffff_100-1.png');
  opacity: 1;
}

.social-icon.twitter {
  background-image: url('../images/twitter_ffffff_100.png');
  background-size: 18px;
  -webkit-transition-property: all;
  transition-property: all;
}

.social-icon.twitter:hover {
  background-image: url('../images/twitter_ffffff_100.png');
}

.social-icon.linkdin {
  background-image: url('../images/linkedin_ffffff_100.png');
  background-size: 18px;
  color: #fff;
}

.social-icon.linkdin:hover {
  background-image: url('../images/linkedin_ffffff_100.png');
}

.social-icon.linkdin._2 {
  -webkit-transition-property: all;
  transition-property: all;
}

.social-icon.rss {
  background-image: url('../images/rss_333333_100.png');
}

.social-icon.rss:hover {
  background-image: url('../images/rss_ffffff_100.png');
}

.social-icon.icon-team {
  background-image: url('../images/facebook_ffffff_100-1.png');
}

.social-icon.icon-team.icon-2 {
  background-image: url('../images/twitter_ffffff_100.png');
}

.social-icon.icon-team.icon-3 {
  background-image: url('../images/linkedin_ffffff_100.png');
}

.social-icon.instagram {
  background-image: url('../images/instagram.png');
  background-size: 18px;
}

.social-icon.instagram:hover {
  background-image: url('../images/instagram.png');
  background-size: 18px;
}

.social-icon.googleplus {
  background-image: url('../images/googleplus.png');
  background-size: 22px;
}

.social-icon.googleplus:hover {
  background-image: url('../images/googleplus.png');
  background-size: 22px;
}

.social-icon.youtube {
  background-image: url('../images/iconmonstr-youtube-6-240.png');
  background-size: 18px;
}

.social-icon.youtube:hover {
  background-image: url('../images/iconmonstr-youtube-6-240.png');
  background-size: 18px;
}

.social-icon._2 {
  margin-right: 6px;
  background-color: #e3543b;
}

.section {
  padding: 100px 10px;
}

.section.no-padding-bottom {
  padding-bottom: 0px;
  background-color: #fafafa;
  background-image: url('../images/1.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.section.no-padding-bottom.photo {
  background-image: url('../images/background.png');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.section.no-padding-bottom.hide {
  display: block;
}

.section.image1 {
  background-image: -webkit-linear-gradient(270deg, rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url('../images/2_3.jpg');
  background-image: linear-gradient(180deg, rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url('../images/2_3.jpg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
  background-attachment: scroll, fixed;
  color: #fff;
}

.section.cta {
  padding-top: 50px;
  padding-bottom: 50px;
  background-repeat: no-repeat;
}

.section.no-padding-top {
  padding-top: 0px;
  padding-right: 0px;
  padding-left: 0px;
}

.section.tint {
  background-color: #eee;
}

.title-wrapper {
  margin-bottom: 50px;
  font-size: 16px;
  line-height: 32px;
  font-weight: 300;
  text-align: center;
}

.title-wrapper.inner {
  margin-bottom: 0px;
  padding-right: 8%;
  padding-left: 8%;
  color: #fff;
}

.title-wrapper.space {
  margin-top: 70px;
}

.divider {
  display: block;
  width: 70px;
  height: 3px;
  margin: 20px auto;
  background-color: #00a3d0;
}

.divider.margin-left {
  margin-left: 0px;
}

.divider.margin-left.less-space {
  margin-top: 15px;
  margin-bottom: 15px;
}

.top-sub-text {
  color: #111;
  font-size: 16px;
  line-height: 32px;
  font-weight: 300;
  letter-spacing: 0px;
  text-transform: none;
}

.top-sub-text.white {
  color: #fff;
}

.tab-content {
  margin-top: 50px;
}

.tab {
  margin-right: 30px;
  margin-left: 30px;
  padding: 14px 6px;
  background-color: transparent;
  opacity: 0.7;
  -webkit-transition: opacity 500ms ease;
  transition: opacity 500ms ease;
  color: #313131;
  font-size: 15px;
  font-weight: 600;
}

.tab:hover {
  opacity: 1;
}

.tab.w--current {
  background-color: transparent;
  opacity: 1;
  color: #e3543b;
}

.tab-menu {
  text-align: center;
}

.tab-icon {
  display: block;
  width: 50px;
  height: 50px;
  margin-right: auto;
  margin-bottom: 15px;
  margin-left: auto;
  background-image: url('../images/magic_363636_100.png');
  background-position: 50% 50%;
  background-size: 40px;
  background-repeat: no-repeat;
}

.tab-icon.icon-2 {
  background-image: url('../images/camera-retro_363636_100.png');
}

.tab-icon.icon-3 {
  background-image: url('../images/heart_363636_100.png');
}

.tab-icon.icon-4 {
  background-image: url('../images/paint-brush_363636_100.png');
}

.button-wrapper {
  margin-top: 20px;
}

.button-wrapper.center {
  text-align: center;
}

.column-0-padding {
  padding-right: 0px;
  padding-left: 0px;
}

.col {
  position: relative;
  overflow: hidden;
  width: 33.333%;
  margin-bottom: 20px;
  padding-right: 10px;
  padding-left: 10px;
  float: left;
}

.col._2 {
  width: 50%;
}

.feature-wrapper {
  display: inline-block;
  width: 25%;
  margin-top: -1px;
  margin-left: -1px;
  padding: 23px;
  float: none;
  border: 1px solid #ccc;
  text-align: left;
}

.feature-wrapper._2 {
  width: 20%;
  text-align: center;
}

.feature-wrapper._3 {
  width: 33.33%;
}

.icon-img {
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
  background-image: url('../images/link_e3543b_100.jpg');
  background-position: 50% 50%;
  background-size: 28px;
  background-repeat: no-repeat;
}

.icon-img.icon-2 {
  background-image: url('../images/magic_363636_100.png');
  background-size: 28px;
}

.icon-img.icon-3 {
  background-image: url('../images/pencil_333333_100.png');
  background-size: 28px;
}

.icon-img.icon-4 {
  background-image: url('../images/star_363636_100.png');
}

.icon-img.icon-5 {
  background-image: url('../images/pencil_333333_100.png');
}

.icon-img.icon-6 {
  background-image: url('../images/heart_363636_100.png');
}

.icon-img.icon-7 {
  background-image: url('../images/cogs_363636_100.png');
}

.icon-img.icon-8 {
  background-image: url('../images/clock-o_333333_100.png');
}

.icon-img.icon-1 {
  background-image: url('../images/iconmonstr-phone-1-240.png');
  background-size: 28px;
}

.icon-img.icon-9 {
  background-image: url('../images/iconmonstr-email-3-240.png');
  background-size: 28px;
}

.brand-slider-img {
  width: 20%;
  height: 80px;
  float: left;
  background-color: #fff;
  background-image: url('../images/1_3.jpg');
  background-position: 50% 50%;
  background-size: 145px;
  background-repeat: no-repeat;
  opacity: 0.5;
  -webkit-transition: opacity 500ms ease-in-out;
  transition: opacity 500ms ease-in-out;
}

.brand-slider-img:hover {
  opacity: 0.8;
}

.brand-slider-img.logo-2 {
  background-image: url('../images/5.jpg');
  background-size: 145px;
}

.brand-slider-img.logo-3 {
  background-image: url('../images/2.jpg');
  background-size: 145px;
}

.brand-slider-img.logo-4 {
  background-image: url('../images/3.jpg');
  background-size: 145px;
}

.brand-slider-img.logo-5 {
  background-image: url('../images/7.jpg');
  background-size: 145px;
}

.footer {
  position: relative;
  padding: 40px 10px 115px;
  background-color: #222;
}

.footer-wrapper {
  height: 226px;
  max-height: 226px;
  padding-right: 10px;
  border-right: 1px solid #4a4a4a;
  color: #fff;
}

.bottom-footer {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  padding-top: 17px;
  padding-bottom: 17px;
  background-color: #00a3d0;
  color: hsla(0, 0%, 100%, .7);
}

.text-align-center {
  text-align: center;
}

.footer-title {
  margin-bottom: 20px;
  padding-top: 5px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
}

.list-footer {
  margin-bottom: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #4a4a4a;
}

.footer-icon {
  position: relative;
  top: -2px;
  width: 30px;
  height: 30px;
  margin-right: 0px;
  float: left;
  background-image: url('../images/map-marker_999999_100.png');
  background-position: 50% 50%;
  background-size: 16px;
  background-repeat: no-repeat;
}

.footer-icon.icon-2 {
  background-image: url('../images/envelope_999999_100.png');
}

.footer-icon.icon-3 {
  background-image: url('../images/phone_999999_100.png');
}

.footer-icon.icon-4 {
  background-image: url('../images/chevron-right_999999_100.png');
  background-size: 10px;
}

.footer-link {
  position: relative;
  display: block;
  -webkit-transition: color 500ms ease;
  transition: color 500ms ease;
  line-height: 26px;
}

.footer-link:hover {
  color: #fff;
}

.footer-link._2 {
  display: inline;
  color: hsla(0, 0%, 100%, .7);
  text-decoration: underline;
}

.footer-link._2:hover {
  color: #fff;
}

.testimonials-slider-wrapper {
  height: auto;
  margin-bottom: -20px;
  background-color: transparent;
}

.slider-navigation {
  position: relative;
  padding-top: 15px;
  font-size: 12px;
}

.slider-navigation.absolute-position {
  position: absolute;
  display: none;
  padding-top: 5px;
}

.testimonials-slide {
  padding-right: 70px;
  padding-left: 70px;
  color: #fff;
  font-size: 14px;
  line-height: 26px;
  text-align: center;
}

.testimonials-photo {
  display: none;
  width: 80px;
  height: 80px;
  margin-right: auto;
  margin-bottom: 20px;
  margin-left: auto;
  border-radius: 100%;
  background-image: url('../images/cleint3.jpg');
  background-size: contain;
}

.testimonials-photo.photo-3 {
  background-image: url('../images/client2.jpg');
}

.testimonials-photo.photo-2 {
  background-image: url('../images/client1.jpg');
}

.testimonials-content {
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 26px;
}

.testimonials-paragraph {
  color: #fff;
}

.testimonials-name {
  color: #fff;
  text-transform: uppercase;
}

.testimonials-sub-name {
  color: hsla(0, 0%, 100%, .89);
  font-size: 13px;
  font-weight: 300;
}

.element-wrapper {
  position: relative;
  left: 0px;
  right: 0px;
  bottom: 0px;

  padding: 20px;
  background-color: #00a3d0;
  font-family: 'PT Serif', serif;
  color: hsla(0, 0%, 100%, .76);
  font-size: 13px;
  line-height: 21px;
  font-style: italic;
  text-align: left;
  letter-spacing: 1px;
}

.element-wrapper._2 {
  position: relative;
  min-height: 154px;
}

.portfolio-title {
  display: inline-block;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid hsla(0, 0%, 100%, .5);
  font-family: Raleway, sans-serif;
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
  font-weight: 600;
}

.portfolio-sub-title {
  float: none;
  clear: both;
  font-family: 'PT Serif', serif;
  color: hsla(0, 0%, 100%, .76);
  font-size: 13px;
  line-height: 21px;
  font-style: italic;
  letter-spacing: 1px;
}

.icon {
  display: block;
  width: 50px;
  height: 50px;
  margin-bottom: 0px;
  padding: 10px;
  float: right;
  border-radius: 100%;
  background-color: #fff;
  -webkit-transition: background-color 500ms ease-in-out;
  transition: background-color 500ms ease-in-out;
}

.icon:hover {
  background-color: #333;
}

.banner {
  padding-top: 100px;
  padding-bottom: 100px;
  background-image: url('../images/1_2.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.banner.photo-2 {
  background-image: -webkit-linear-gradient(270deg, hsla(0, 0%, 53%, .8), hsla(0, 0%, 53%, .8)), url('../images/slide08.jpg');
  background-image: linear-gradient(180deg, hsla(0, 0%, 53%, .8), hsla(0, 0%, 53%, .8)), url('../images/slide08.jpg');
  background-size: auto, cover;
}

.banner.image3 {
  background-image: url('../images/1_2.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.banner.image4 {
  background-image: url('../images/1_2.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.banner.image1 {
  background-image: url('../images/2_2.jpg');
}

.skills-block {
  margin-top: 30px;
}

.skills-bar {
  display: block;
  margin-bottom: 15px;
  -webkit-transition: background-color 500ms ease, color 500ms ease, -webkit-transform 500ms ease;
  transition: background-color 500ms ease, color 500ms ease, -webkit-transform 500ms ease;
  transition: background-color 500ms ease, color 500ms ease, transform 500ms ease;
  transition: background-color 500ms ease, color 500ms ease, transform 500ms ease, -webkit-transform 500ms ease;
}

.skills-bar:hover {
  -webkit-transform: translate(-10px, 0px);
  -ms-transform: translate(-10px, 0px);
  transform: translate(-10px, 0px);
  color: #00a3d0;
}

.skills-percent-bar {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 3px;
  margin-top: 10px;
  background-color: #e3e3e3;
}

.skills-bar-overlay {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 80%;
  height: 3px;
  background-color: #00a3d0;
}

.skills-bar-overlay.bar-2 {
  width: 100%;
}

.skills-bar-overlay.bar-3 {
  width: 65%;
}

.skills-bar-overlay.bar-4 {
  width: 46%;
}

.skills-bar-overlay.bar-5 {
  width: 91%;
}

.team-wrapper {
  position: relative;
  overflow: hidden;
}

.team-overlay {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  padding-right: 20px;
  padding-left: 20px;
  background-image: -webkit-linear-gradient(270deg, rgba(79, 79, 79, .8), rgba(79, 79, 79, .8));
  background-image: linear-gradient(180deg, rgba(79, 79, 79, .8), rgba(79, 79, 79, .8));
  opacity: 0;
  -webkit-transition: opacity 500ms ease;
  transition: opacity 500ms ease;
  color: #fff;
  text-align: center;
}

.team-overlay:hover {
  opacity: 1;
}

.team-content {
  position: relative;
  top: 50%;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
}

.team-name {
  margin-bottom: 5px;
  font-weight: 600;
}

.team-sub-text {
  margin-bottom: 10px;
  color: #e3543b;
  font-size: 13px;
}

.paragraph-white {
  color: #fff;
  font-size: 13px;
}

.hero {
  text-align: center;
}

.input {
  height: 45px;
  margin-bottom: 15px;
  border: 1px solid #ebebeb;
  border-radius: 15px;
}

.input.text-area {
  height: auto;
  padding-top: 15px;
  padding-bottom: 105px;
}

.input.inline {
  height: 51px;
  border-top-right-radius: 21px;
  border-bottom-right-radius: 21px;
}

.success-message {
  padding: 22px;
  border-radius: 5px;
  background-color: #50d49f;
  text-align: center;
}

.form-paragraph {
  margin-bottom: 0px;
  color: #fff;
}

.error-message {
  margin-top: 15px;
  border-radius: 5px;
  background-color: #ff6161;
  text-align: center;
}

.slider {
  position: relative;
  height: 700px;
  background-image: url('../images/1_4.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.slide {
  padding-right: 10px;
  padding-left: 10px;
  background-image: url('../images/1_4.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.slide.photo-2 {
  background-image: -webkit-linear-gradient(270deg, rgba(0, 0, 0, .3), rgba(0, 0, 0, .3)), url('../images/slide03.jpg');
  background-image: linear-gradient(180deg, rgba(0, 0, 0, .3), rgba(0, 0, 0, .3)), url('../images/slide03.jpg');
}

.slide.photo-3 {
  background-image: -webkit-linear-gradient(270deg, rgba(0, 0, 0, .3), rgba(0, 0, 0, .3)), url('../images/slide02.jpg');
  background-image: linear-gradient(180deg, rgba(0, 0, 0, .3), rgba(0, 0, 0, .3)), url('../images/slide02.jpg');
}

.slide.image2 {
  background-image: url('../images/2_3.jpg');
}

.slider-container {
  position: absolute;
  left: 0px;
  top: 50%;
  right: 0px;
  z-index: 10;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
  color: #fff;
  text-align: center;
}

.banner-text-small {
  display: inline-block;
  margin-bottom: 10px;
  padding: 10px 27px;
  border-radius: 30px;
  background-color: rgba(0, 0, 0, .49);
  color: #fff;
  font-size: 12px;
  line-height: 26px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.banner-text-large {
  margin-bottom: 10px;
  font-size: 48px;
  line-height: 70px;
  font-weight: 600;
  text-transform: uppercase;
}

.banner-text-medium {
  margin-bottom: 30px;
  font-family: Raleway, sans-serif;
  font-size: 22px;
  line-height: 32px;
  font-weight: 300;
}

.col-space-left {
  margin-top: -10px;
  padding-left: 20px;
}

.col-space-right {
  padding-right: 20px;
}

.home-img {
  min-width: 100%;
}

.mini-slider {
  height: auto;
  background-color: transparent;
}

.footer-img {
  margin-top: 30px;
}

.logo-text {
  display: none;
  margin-top: 10px;
  margin-bottom: 30px;
  color: #fff;
  font-size: 20px;
  line-height: 25px;
  font-weight: 700;
  letter-spacing: 1px;
}

.space {
  margin-top: 50px;
}

.space.text-align-left {
  text-align: left;
}

.hide {
  display: none;
}

.icontree {
  position: relative;
  display: none;
  padding-right: 0px;
  padding-left: 0px;
}

.icon-tree-col {
  padding-right: 0px;
  padding-left: 0px;
}

.icontree-label-right {
  position: relative;
  z-index: 10;
  margin-bottom: 30px;
  margin-left: -35px;
  padding-top: 10px;
  padding-bottom: 10px;
  float: none;
  -webkit-transform-origin: 0% 50% 0px;
  -ms-transform-origin: 0% 50% 0px;
  transform-origin: 0% 50% 0px;
  text-align: left;
}

.icon2 {
  position: relative;
  left: 0px;
  top: 0px;
  display: inline-block;
  width: 70px;
  height: 70px;
  padding: 20px;
  float: left;
  border-style: solid;
  border-width: 2px;
  border-color: #333 #333 #444;
  border-radius: 100%;
  background-color: #fff;
  text-align: center;
}

.icon2.right {
  float: right;
}

.icontree-label-heading {
  display: inline-block;
  margin-bottom: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-left: 10px;
  border-bottom: 2px solid #444;
  color: #00a3d0;
  font-size: 20px;
  line-height: 25px;
  text-transform: uppercase;
}

.icontree-label-text {
  display: inline-block;
  padding-right: 10px;
  padding-left: 10px;
  float: left;
}

.icontree-label-left {
  position: relative;
  z-index: 10;
  margin-right: -35px;
  margin-bottom: 30px;
  -webkit-transform-origin: 100% 0% 0px;
  -ms-transform-origin: 100% 0% 0px;
  transform-origin: 100% 0% 0px;
  text-align: right;
}

.icontree-stem {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 0;
  display: block;
  width: 2px;
  margin: 10px auto 60px;
  background-color: #444;
}

.category-link {
  display: block;
  padding-top: 1px;
  padding-bottom: 1px;
  color: #00a3d0;
  font-weight: 500;
}

.category-link:hover {
  color: #333;
}

.blog-sidebar-element {
  margin-bottom: 30px;
}

.tag {
  display: inline-block;
  margin-right: 7px;
  margin-bottom: 7px;
  padding: 15px 25px;
  border-radius: 20px;
  background-color: #00a3d0;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  text-align: center;
}

.tag:hover {
  background-color: #333;
  color: #00a3d0;
}

.blogpost-wrapper {
  margin-bottom: 40px;
  padding-right: 10px;
  padding-left: 10px;
}

.blog-social-wrapper {
  margin-top: 30px;
  text-align: left;
}

.social {
  display: inline-block;
  margin-right: 10px;
  float: left;
}

.download-icon {
  display: block;
  width: 40px;
  height: 40px;
  margin-right: auto;
  margin-bottom: 10px;
  margin-left: auto;
  background-image: url('../images/iconmonstr-download-17-240.png');
  background-position: 50% 50%;
  background-size: 28px;
  background-repeat: no-repeat;
}

.home-icon {
  display: block;
  width: 40px;
  height: 40px;
  margin-bottom: 0px;
  background-image: url('../images/clock-o_333333_100.png');
  background-position: 50% 50%;
  background-size: 28px;
  background-repeat: no-repeat;
}

.element-block {
  margin-bottom: 70px;
}

.map {
  display: none;
}

.contact-form {
  padding-left: 10px;
}

.contact-element-wrapper {
  margin-bottom: 30px;
  padding-left: 10px;
}

.phone-icon {
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
  background-image: url('../images/iconmonstr-phone-4-240.png');
  background-position: 50% 50%;
  background-size: 28px;
  background-repeat: no-repeat;
}

.accordion-link {
  display: block;
  max-width: 75%;
  margin-bottom: 20px;
  color: #fff;
  text-align: center;
}

.accordion-toggle {
  display: block;
  padding-top: 15px;
  padding-bottom: 15px;
  border-top: 1px solid hsla(0, 0%, 100%, .15);
  background-color: #00a3d0;
  -webkit-transition: color 500ms ease, background-color 500ms ease;
  transition: color 500ms ease, background-color 500ms ease;
  color: #fff;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
}

.accordion-toggle:hover {
  background-color: #333;
  color: #00a3d0;
}

.accordion-toggle.w--open {
  background-color: #333;
  color: #00a3d0;
}

.accordion-list.w--open {
  position: relative;
  padding: 20px;
  background-color: #eee;
  text-align: left;
}

.experience-block {
  display: block;
  max-width: 75%;
  margin-right: auto;
  margin-bottom: 30px;
  margin-left: auto;
  padding-top: 20px;
  border: 1px solid #00a3d0;
  background-color: #fff;
  color: #333;
}

.modal-background {
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 9000;
  display: none;
  background-color: rgba(0, 0, 0, .9);
  opacity: 0;
  color: #fff;
}

.blue {
  position: relative;
  top: -3px;
  color: #00a3d0;
  font-size: 16px;
  letter-spacing: 0.7px;
}

.base {
  position: relative;
  display: block;
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
  border-radius: 100%;
  background-color: #e3e3e3;
  text-align: center;
}

.base-center {
  position: absolute;
  left: 0px;
  top: 50%;
  right: 0px;
  z-index: 1;
  display: block;
  width: 90px;
  height: 90px;
  margin-right: auto;
  margin-left: auto;
  border-radius: 100%;
  background-color: #fff;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
}

.base-grid {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 50px;
  height: 50px;
}

.base-number {
  position: absolute;
  left: 0px;
  top: 50%;
  right: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
}

.quarter-grid-slice {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  border-bottom-left-radius: 100%;
  background-color: #00a3d0;
  -webkit-transform-origin: 100% 0% 0px;
  -ms-transform-origin: 100% 0% 0px;
  transform-origin: 100% 0% 0px;
}

.modal-body {
  position: absolute;
  left: 0px;
  top: 50%;
  right: 0px;
  display: block;
  max-width: 90%;
  margin-right: auto;
  margin-left: auto;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
}

.modal-header {
  min-width: 960px;
  padding: 10px;
  border: 1px none #000;
  background-color: #333;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
}

.modal-table-body {
  min-width: 960px;
  padding: 10px;
  border-bottom: 1px solid hsla(0, 0%, 100%, .2);
  background-color: #00a3d0;
  color: #fff;
}

.close-modal {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 20px 30px;
  color: #fff;
  font-size: 24px;
}

.percentage-block {
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
  -webkit-transition: background-color 500ms ease, color 500ms ease, -webkit-transform 500ms ease;
  transition: background-color 500ms ease, color 500ms ease, -webkit-transform 500ms ease;
  transition: background-color 500ms ease, color 500ms ease, transform 500ms ease;
  transition: background-color 500ms ease, color 500ms ease, transform 500ms ease, -webkit-transform 500ms ease;
  text-align: left;
}

.percentage-block:hover {
  -webkit-transform: translate(0px, -12px);
  -ms-transform: translate(0px, -12px);
  transform: translate(0px, -12px);
}

.endorsment-label {
  margin-bottom: 40px;
  float: left;
}

.slider-btn {
  display: inline-block;
  margin: 10px;
  padding: 15px 25px;
  border-radius: 20px;
  background-color: #00a3d0;
  color: #fff;
}

.slider-btn:hover {
  background-color: #333;
  color: #00a3d0;
}

.img {
  display: block;
  min-width: 100%;
}

.brand-slider {
  height: auto;
  background-color: transparent;
}

.brand-slider-nav {
  display: none;
}

.modal-table {
  overflow: auto;
  min-width: 960px;
  border-style: none;
  border-top-width: 1px;
  border-top-color: #000;
}

.table-img {
  min-width: 100%;
}

.signup-form {
  position: relative;
  display: block;
  max-width: 360px;
  margin-top: 30px;
  margin-right: auto;
  margin-left: auto;
}

.signup-form-wrapper {
  display: none;
  opacity: 0;
}

html.w-mod-js *[data-ix="slide-out-portfolio-title"] {
  opacity: 0;
  -webkit-transform: translate(-30px, 0px);
  -ms-transform: translate(-30px, 0px);
  transform: translate(-30px, 0px);
}

html.w-mod-js *[data-ix="slide-out-portfolio-title-2"] {
  opacity: 0;
  -webkit-transform: translate(-30px, 0px);
  -ms-transform: translate(-30px, 0px);
  transform: translate(-30px, 0px);
}

html.w-mod-js *[data-ix="slider"] {
  opacity: 0;
  -webkit-transform: scale(0.5, 0.5);
  -ms-transform: scale(0.5, 0.5);
  transform: scale(0.5, 0.5);
}

html.w-mod-js *[data-ix="slide-fade"] {
  opacity: 0;
  -webkit-transform: translate(-100px, 0px);
  -ms-transform: translate(-100px, 0px);
  transform: translate(-100px, 0px);
}

html.w-mod-js *[data-ix="slide-fade-2"] {
  opacity: 0;
  -webkit-transform: translate(100px, 0px);
  -ms-transform: translate(100px, 0px);
  transform: translate(100px, 0px);
}

html.w-mod-js *[data-ix="why-choose-fade"] {
  opacity: 0;
  -webkit-transform: translate(-100px, 0px);
  -ms-transform: translate(-100px, 0px);
  transform: translate(-100px, 0px);
}

html.w-mod-js *[data-ix="why-choose-fade-2"] {
  opacity: 0;
  -webkit-transform: translate(-100px, 0px);
  -ms-transform: translate(-100px, 0px);
  transform: translate(-100px, 0px);
}

html.w-mod-js *[data-ix="why-choose-fade-3"] {
  opacity: 0;
  -webkit-transform: translate(-100px, 0px);
  -ms-transform: translate(-100px, 0px);
  transform: translate(-100px, 0px);
}

html.w-mod-js *[data-ix="why-choose-fade-4"] {
  opacity: 0;
  -webkit-transform: translate(-100px, 0px);
  -ms-transform: translate(-100px, 0px);
  transform: translate(-100px, 0px);
}

html.w-mod-js *[data-ix="scale-icon"] {
  opacity: 0;
  -webkit-transform: scale(0.01, 0.01);
  -ms-transform: scale(0.01, 0.01);
  transform: scale(0.01, 0.01);
}

@media (max-width: 991px) {
  body {
    padding-left: 0px;
  }
  .navigation {
    position: relative;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: auto;
    overflow: visible;
    width: auto;
    height: auto;
    padding: 10px 15px;
    float: none;
  }
  .navigation-link {
    padding-top: 13px;
    padding-bottom: 13px;
    padding-left: 20px;
    color: #fff;
  }
  .navigation-link:hover {
    background-color: rgba(0, 0, 0, .83);
  }
  .navigation-menu {
    background-color: rgba(0, 0, 0, .74);
  }
  .brand {
    display: inline-block;
    max-width: 140px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-right: 0px;
    padding-bottom: 5px;
    padding-left: 0px;
  }
  .brand.w--current {
    max-width: 140px;
    margin-top: 0px;
    margin-bottom: 0px;
    float: left;
  }
  .banner2 {
    height: 70%;
  }
  .social-wrapper {
    display: block;
    margin-bottom: 0px;
    padding-top: 20px;
    padding-right: 10px;
    padding-bottom: 17px;
    float: right;
  }
  .banner {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .slider {
    height: 500px;
  }
  .banner-text-large {
    font-size: 40px;
  }
  .banner-text-medium {
    font-size: 20px;
  }
  .hamburger {
    margin-top: 11px;
    float: right;
    color: #00a3d0;
    font-size: 24px;
  }
  .hamburger.w--open {
    border-radius: 20px;
    background-color: #00a3d0;
  }
  .col-space-left {
    padding-left: 0px;
  }
  .col-space-right {
    padding-right: 0px;
  }
  .logo-text {
    margin-bottom: 0px;
    font-size: 16px;
  }
  .accordion-link {
    max-width: 90%;
  }
  .modal-body {
    overflow: scroll;
  }
  .modal-header {
    padding-right: 0px;
    padding-left: 0px;
  }
  .modal-table-body {
    padding-right: 0px;
    padding-left: 0px;
  }
}

@media (max-width: 767px) {
  body {
    height: auto;
  }
  .navigation {
    padding-left: 5px;
  }
  .navigation-link {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .brand {
    padding-left: 15px;
    text-align: left;
  }
  .banner2 {
    height: 50%;
  }
  .social-wrapper {
    padding-top: 18px;
    padding-bottom: 11px;
  }
  .section {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .section.no-padding-bottom.photo {
    background-image: none;
  }
  .tab {
    margin-right: 12px;
    margin-left: 12px;
  }
  .col {
    width: 50%;
  }
  .feature-wrapper {
    width: 50%;
  }
  .feature-wrapper._3 {
    width: 50%;
  }
  .brand-slider-img {
    width: 48%;
    margin-right: -1px;
    margin-left: -1px;
  }
  .footer {
    padding-top: 30px;
    padding-bottom: 105px;
  }
  .footer-wrapper {
    height: auto;
    margin-bottom: 20px;
    border-right-color: transparent;
  }
  .footer-title {
    margin-bottom: 20px;
  }
  .banner.image4 {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .team-wrapper {
    margin-bottom: 20px;
  }
  .slider {
    height: 400px;
  }
  .banner-text-large {
    margin-bottom: 5px;
    font-size: 37px;
    line-height: 50px;
  }
  .banner-text-medium {
    line-height: 30px;
  }
  .hamburger {
    margin-top: 9px;
  }
  .col-space-left {
    margin-top: 20px;
  }
  .col-space-right {
    margin-bottom: 20px;
  }
  .contact-form {
    max-width: 100%;
  }
  .accordion-link {
    max-width: 100%;
  }
  .modal-body {
    position: relative;
    top: 80px;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
}

@media (max-width: 479px) {
  .banner2 {
    height: 500px;
  }
  .social-wrapper {
    padding-top: 5px;
    padding-bottom: 0px;
    float: none;
    clear: both;
    text-align: center;
  }
  .social-icon {
    margin-right: 1.3%;
    margin-left: 1.3%;
  }
  .tab {
    display: inline-block;
    margin: 10px;
    text-align: center;
  }
  .col {
    width: 100%;
  }
  .feature-wrapper {
    width: 100%;
  }
  .feature-wrapper._3 {
    width: 100%;
  }
  .brand-slider-img {
    width: 100%;
  }
  .testimonials-slide {
    padding-right: 15px;
    padding-left: 15px;
  }
  .slider {
    height: 360px;
  }
  .banner-text-large {
    font-size: 30px;
    line-height: 40px;
  }
  .banner-text-medium {
    font-size: 18px;
    line-height: 28px;
  }
  .blogpost-wrapper {
    padding-right: 0px;
    padding-left: 0px;
  }
}

