/* Global Values */
body {
  overflow-x: hidden !important;
}
.element-wrapper > a.w-inline-block {
  float: right;
}
.element-block:last-child,
.blog-sidebar-element:last-child {
  margin-bottom: 0px;
}
.element-wrapper h3 {
  display: inline-block;
  margin-top: 0px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  font-family: Raleway, sans-serif;
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
  font-weight: 600;
}
.footer h3 {
  margin-bottom: 20px;
  padding-top: 5px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
}
.footer a:not(.mbss-btn-icon):not(.re-icon):not(._2) {
  position: relative;
  display: block;
  -webkit-transition: color 500ms ease;
  transition: color 500ms ease;
  line-height: 26px;
  color: #00a3d0;
  text-decoration: none;
}
@media (max-width: 991px) {
  .social-wrapper {
    position: relative;
    display: block !important;
  }
}
.logoWrapper {
  float: left;
}
div.p {
  color: #777;
  line-height: 26px;
  margin-bottom: 5px;
  text-transform: none;
}
ul li.list-footer {
  list-style: none;
}
.sliderButton .mbss-content a {
  background-color: #00a3d0;
  border-radius: 20px;
  color: #fff;
  display: inline-block;
  padding: 15px 25px;
}
.sliderButton .mbss-content a:hover {
  background-color: #333;
  color: #00a3d0;
}
.feature-wrapper .home-icon {
  background-image: none;
}
.testimonialBackground.section {
  background-position: 50% 50%;
  background-attachment: fixed;
  background-size: cover;
}
.brand-slider-img {
  background-image: none;
  text-align: center;
}
.brand-slider-img img {
  margin-left: auto;
  margin-right: auto;
}
.faq-item .mbss-listItem-text-wrapper:nth-child(1) .mbss-content {
  background-color: #00a3d0;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  color: #fff;
  display: block;
  font-weight: 700;
  padding-bottom: 15px;
  padding-top: 15px;
  text-align: center;
  text-transform: uppercase;
  transition: color 500ms ease 0s, background-color 500ms ease 0s;
  cursor: pointer;
}
.faq-item .mbss-listItem-text-wrapper:nth-child(1) .mbss-content:hover {
  background-color: #333;
  color: #00a3d0;
}
.faq-item .mbss-listItem-text-wrapper:nth-child(2) .mbss-content {
  background-color: #eee;
  padding: 20px;
  position: relative;
  text-align: left;
  color: #777;
  line-height: 26px;
  text-transform: none;
  /*visibility:hidden;*/
}
.faq-item .mbss-listItem-text-wrapper:nth-child(2) {
  display: none;
}
.accordiantoggle2 {
  color: white;
}
.higherIndex {
  z-index: 1000;
}
.blogBanner {
  background-image: url(images/10.jpg);
}
.portfolioElement {
  margin-left: 1%;
  margin-right: 1%;
  width: 100%;
  overflow: visible;
}
.portfolioElement .mbss-listItem2-image-wrapper {
  display: inline-block;
  float: right;
}
.repeaterWrapper .mbss-listItem2-inner {
  margin-left: auto;
  margin-right: auto;
  max-width: 940px;
}
.repeaterWrapper {
  text-align: center;
}
.repeaterSection:nth-child(2n+1) {
  background-color: #eee;
}
.navigation {
  z-index: 999999;
}
.feature-wrapper {
  float: left;
}
.portfolioElement .mbss-text {
  padding-right: 51px;
}
/* Size specific */
/* all devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .portfolioElement {
    margin-left: 1%;
    margin-right: 1%;
    width: 31.333%;
  }
  .feature-wrapper {
    width: 49%;
  }
  .feature-wrapper._3 {
    width: 33%;
  }
}
@media (min-width: 992px) {
  .feature-wrapper {
    width: 24.5%;
  }
}
.element-wrapper {
    position: relative;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 20px;
    background-color: #00a3d0;
    font-family: 'PT Serif',serif;
    color: hsla(0,0%,100%,.76);
    font-size: 13px;
    line-height: 21px;
    font-style: italic;
    text-align: left;
    letter-spacing: 1px;
}
.testimonial .editImageX {
    top:77%!important;
}
.faq-item .mbss-listItem-text-wrapper:nth-child(1) p {
    color: #fff;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    line-height:17px;
}
.faq-item .mbss-listItem-text-wrapper:nth-child(1) p:hover {
color: #00a3d0;
}
.portfolioElement .mbss-listItem2-text-wrapper p {
    font-family: 'PT Serif',serif;
    color: hsla(0,0%,100%,.76);
    font-size: 13px;
    line-height: 21px;
    font-style: italic;
    text-align: left;
    letter-spacing: 1px;
}